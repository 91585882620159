import { reservationCalcSettingService } from "@/services/facility/reservation-calc-setting/reservation-calc-setting.service";
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {alertService} from "@/services/alert/alert.service";
import allLocales from '@fullcalendar/core/locales-all';
import { EventBus } from "@/event-bus";
export default {
    components: {
        FullCalendar,
    },
    data() {
        return {
            data: {},
            dataReady: false,
            filter: {
                page: 10,
            },
            calendarOptions: {
                locales: allLocales,
                locale: this.$i18n.locale,
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                selectable: false,
                selectOverlap: false,
                eventOverlap: false,
            },
            calendarData: [],
        }
    },
    computed: {
    },
    async mounted() {
        await this.list();

        EventBus.$on('changeLocal', ()=>{
            this.calendarData.forEach(item => {
                item.locale = this.$i18n.locale;
            });

            this.calendarOptions.locale = this.$i18n.locale;
        });
    },
    methods: {
        async list() {
            alertService.loading();
            reservationCalcSettingService.list()
            .then((response) => {
                this.data = response.data;
                this.dataReady = true
                let events = [];
                response.data.forEach(parking => {
                    parking.calc_setting.forEach(setting => {
                        events.push({
                            start: setting.date,
                            end : setting.date,
                            title: `
                                ${setting.unit_time}分 ${setting.unit_toll}円
                                <br>
                                ${setting.day_toll_max == undefined ? "" : "1日 " + setting.day_toll_max + "円"}
                            `,
                            parkingId: setting.parking_id,
                        })
                    });
                    this.setReserved(events);                    
                    events = [];
                });
            })
            
        },

        async setReserved(events) {
            this.calendarData.push({events,...this.calendarOptions, ...{eventContent: this.reRenderCalendar},...{locale : this.$i18n.locale}})
            this.$emit('calendar', !this.calendarData.length);
            alertService.close();
        },

        reRenderCalendar(arg) {
            return {
                html :  `<strong>
                            ${arg.event.title}
                        </strong>`,
            };
        },
    }

}
